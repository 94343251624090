// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-rubbish-at-st-clere-js": () => import("./../../../src/pages/blog/rubbish-at-st-clere.js" /* webpackChunkName: "component---src-pages-blog-rubbish-at-st-clere-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-sevenoaks-spring-clean-js": () => import("./../../../src/pages/events/sevenoaks-spring-clean.js" /* webpackChunkName: "component---src-pages-events-sevenoaks-spring-clean-js" */),
  "component---src-pages-events-spring-clean-2023-js": () => import("./../../../src/pages/events/spring-clean-2023.js" /* webpackChunkName: "component---src-pages-events-spring-clean-2023-js" */),
  "component---src-pages-events-spring-clean-2024-js": () => import("./../../../src/pages/events/spring-clean-2024.js" /* webpackChunkName: "component---src-pages-events-spring-clean-2024-js" */),
  "component---src-pages-events-spring-clean-js": () => import("./../../../src/pages/events/spring-clean.js" /* webpackChunkName: "component---src-pages-events-spring-clean-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-log-your-litter-js": () => import("./../../../src/pages/log-your-litter.js" /* webpackChunkName: "component---src-pages-log-your-litter-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sponsor-js": () => import("./../../../src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */)
}

